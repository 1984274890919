import { Environment } from './environment.interface';

// qa
export const environment: Environment = {
  production: false,
  name: 'dev',
  apiUrl: 'http://10.11.10.129:7171/api',
  // apiUrl: 'https://localhost:7283/api',
  frontUrl: 'http://10.11.10.129:4200/',
  version: 'v1.0.0',
  grant_type: 'password',
  client_secret: 'NdQjJZnYzZnOEbmrCtKE1CXiRC9iuYRx',
  client_id: 'scl-api',
  keyCloackAdminUrl:
    'http://10.11.10.110:8080/admin/realms/SCL',
  kcUrl:
    'http://10.11.10.110:8080/realms/SCL/protocol/openid-connect',
  cryptoKey: 'sclbo24'  
};